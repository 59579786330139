import React from 'react';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ExcelIcon from '../../../App/assets/icons/calculator.svg';
import { ADMIN_ROLE } from '../../../utils/constants';

export default [
    {
        name: "projects_list",
        path: "/financial/projects",
        pathFormat: "/financial/projects*",
        icon: <FormatListBulletedIcon />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "snapshots",
        path: "/financial/snapshots",
        pathFormat: "/financial/snapshots*",
        icon: <SettingsBackupRestoreIcon />,
        permission: "pilotage.managePilotage",
        roles: []
    },
    {
        name: "costs_management",
        path: "/financial/collaborators/costs",
        pathFormat: "/financial/collaborators/costs*",
        icon: <AttachMoney />,
        permission: "pilotage.managePilotage",
        roles: [ADMIN_ROLE]
    },
    {
        name: "administration",
        path: "/administration",
        pathFormat: "/administration",
        icon: <SettingsIcon />,
        permission: "pilotage.managePilotage",
        roles: [ADMIN_ROLE]
    },
    {
        name: "consumption_management",
        path: "/financial/collaborators/consumption",
        pathFormat: "/financial/collaborators/consumption*",
        icon: <img src={ExcelIcon} width={ "23px"} alt="Export icon" />,
        permission: "pilotage.managePilotage",
        roles: [ADMIN_ROLE]
    }

];
