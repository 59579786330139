// NAMING CONVENTION: 
// + all action types should mention 'FINANCIAL'
// + request actions should start with [GET, POST, PUT, DELETE] depending on http method
// + actions of type setters (modify store state) should start with 'SET'
// + actions that reset the store to its initial state should start with 'CLEAR'

/************* INDICATORS **************/
export const GET_GLOBAL_FINANCIAL_INDICATORS = 'GET_GLOBAL_FINANCIAL_INDICATORS';
export const GET_PROJECT_FINANCIAL_INDICATORS = 'GET_PROJECT_FINANCIAL_INDICATORS';
export const PUT_PROJECT_FINANCIAL_INDICATORS = 'PUT_PROJECT_FINANCIAL_INDICATORS';

export const SET_GLOBAL_FINANCIAL_INDICATORS = 'SET_GLOBAL_FINANCIAL_INDICATORS';
export const SET_PROJECT_FINANCIAL_INDICATORS = 'SET_PROJECT_FINANCIAL_INDICATORS';

export const CLEAR_PROJECT_FINANCIAL_INDICATORS = 'CLEAR_PROJECT_FINANCIAL_INDICATORS';

export const TAKE_PROJECT_FINANCIAL_SNAPSHOT = 'TAKE_PROJECT_FINANCIAL_SNAPSHOT';

/************* PROJECTS **************/
export const GET_FINANCIAL_PROJECTS = 'GET_FINANCIAL_PROJECTS';
export const GET_SELECTED_FINANCIAL_PROJECT = 'GET_SELECTED_FINANCIAL_PROJECT';
export const GET_FINANCIAL_SUBPROJECTS = 'GET_FINANCIAL_SUBPROJECTS';
export const EXPORT_FINANCIAL_PROJECTS = 'EXPORT_FINANCIAL_PROJECTS';
export const EXPORT_FINANCIAL_PROJECT_DASHBOARD = 'EXPORT_FINANCIAL_PROJECT_DASHBOARD';

export const SET_FINANCIAL_PROJECTS = 'SET_FINANCIAL_PROJECTS';
export const SET_FINANCIAL_SUBPROJECTS_SORT = 'SET_FINANCIAL_SUBPROJECTS_SORT';
export const SET_FINANCIAL_SUBPROJECTS = 'SET_FINANCIAL_SUBPROJECTS';
export const SET_SELECTED_FINANCIAL_PROJECT = 'SET_SELECTED_FINANCIAL_PROJECT';
export const SET_FINANCIAL_PROJECTS_PAGINATION = 'SET_FINANCIAL_PROJECTS_PAGINATION';
export const SET_FINANCIAL_PROJECTS_GRID_VIEW = 'SET_FINANCIAL_PROJECTS_GRID_VIEW';
export const SET_FINANCIAL_PROJECTS_FILTER = 'SET_FINANCIAL_PROJECTS_FILTER';
export const SET_FINANCIAL_PROJECTS_SORT = 'SET_FINANCIAL_PROJECTS_SORT';
export const SET_FINANCIAL_SUBPROJECTS_PAGINATION = 'SET_FINANCIAL_SUBPROJECTS_PAGINATION';
export const UPDATE_FINANCIAL_PROJECT = 'UPDATE_FINANCIAL_PROJECT'
export const UPDATE_FINANCIAL_SUB_PROJECT = 'UPDATE_FINANCIAL_SUB_PROJECT'
export const CLEAR_SELECTED_FINANCIAL_PROJECT = 'CLEAR_SELECTED_FINANCIAL_PROJECT';
export const CLEAR_FINANCIAL_PROJECTS_PAGINATION = 'CLEAR_FINANCIAL_PROJECTS_PAGINATION';
export const CLEAR_FINANCIAL_PROJECTS_FILTER = 'CLEAR_FINANCIAL_PROJECTS_FILTER';
export const CLEAR_FINANCIAL_PROJECTS_SORT = 'CLEAR_FINANCIAL_PROJECTS_SORT';
export const CLEAR_FINANCIAL_SUBPROJECTS_SORT = 'CLEAR_FINANCIAL_SUBPROJECTS_SORT';
export const CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION = 'CLEAR_FINANCIAL_SUBPROJECTS_PAGINATION';

/************* COLLABORATOR_CONSUMPTIONS **************/
export const SET_COLLABORATOR_CONSUMPTIONS = 'SET_COLLABORATOR_CONSUMPTIONS';
export const SET_COLLABORATOR_CONSUMPTIONS_DETAILS = 'SET_COLLABORATOR_CONSUMPTIONS_DETAILS';

/************* COLLABORATOR **************/
export const GET_FINANCIAL_PROJECT_COLLABORATORS = 'GET_FINANCIAL_PROJECT_COLLABORATORS';
export const GET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'GET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS';

export const SET_FINANCIAL_PROJECT_COLLABORATORS = 'SET_FINANCIAL_PROJECT_COLLABORATORS';
export const SET_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION = 'SET_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION';
export const SET_FINANCIAL_PROJECT_COLLABORATORS_SORT = 'SET_FINANCIAL_PROJECT_COLLABORATORS_SORT';
export const SET_FINANCIAL_PROJECT_COLLABORATORS_FILTER = 'SET_FINANCIAL_PROJECT_COLLABORATORS_FILTER';
export const SET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'SET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS';


export const CLEAR_FINANCIAL_PROJECT_COLLABORATORS = 'CLEAR_FINANCIAL_PROJECT_COLLABORATORS';
export const CLEAR_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION = 'CLEAR_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION';
export const CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SORT = 'CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SORT';
export const CLEAR_FINANCIAL_PROJECT_COLLABORATORS_FILTER = 'CLEAR_FINANCIAL_PROJECT_COLLABORATORS_FILTER';
export const CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS = 'CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS';
export const CLEAR_FINANCIAL_MONITORING_SNAPSHOTS = 'CLEAR_FINANCIAL_MONITORING_SNAPSHOTS';
export const CLEAR_FINANCIAL_MONITORING_FILTER = 'CLEAR_FINANCIAL_MONITORING_FILTER'


/************* SNAPSHOTS **************/
export const GET_FINANCIAL_PROJECT_SNAPSHOTS = 'GET_FINANCIAL_PROJECT_SNAPSHOTS';
export const GET_MANUAL_FINANCIAL_SNAPSHOTS = 'GET_MANUAL_FINANCIAL_SNAPSHOTS';
export const GET_ALL_FINANCIAL_PROJECT_SNAPSHOTS = 'GET_ALL_FINANCIAL_PROJECT_SNAPSHOTS';
export const GET_FINANCIAL_PROJECT_SNAPSHOT = 'GET_FINANCIAL_PROJECT_SNAPSHOT';
export const PUT_FINANCIAL_PROJECT_SNAPSHOT = 'PUT_FINANCIAL_PROJECT_SNAPSHOT';

export const SET_FINANCIAL_PROJECT_SNAPSHOTS = 'SET_FINANCIAL_PROJECT_SNAPSHOTS';
export const SET_FINANCIAL_PROJECT_SNAPSHOT = 'SET_FINANCIAL_PROJECT_SNAPSHOT';
export const SET_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION = 'SET_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION';
export const SET_FINANCIAL_PROJECT_SNAPSHOTS_SORT = 'SET_FINANCIAL_PROJECT_SNAPSHOTS_SORT';
export const SET_MANUAL_FINANCIAL_SNAPSHOTS = 'SET_MANUAL_FINANCIAL_SNAPSHOTS';
export const SET_MANUAL_FINANCIAL_SNAPSHOTS_FILTER = 'SET_MANUAL_FINANCIAL_SNAPSHOTS_FILTER';
export const SET_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION = 'SET_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION';
export const SET_MANUAL_FINANCIAL_SNAPSHOTS_SORT = 'SET_MANUAL_FINANCIAL_SNAPSHOTS_SORT';
export const SET_ALL_FINANCIAL_PROJECTS_SNAPSHOTS = 'SET_ALL_FINANCIAL_PROJECTS_SNAPSHOTS'

export const CLEAR_FINANCIAL_PROJECT_SNAPSHOTS = 'CLEAR_FINANCIAL_PROJECT_SNAPSHOTS';
export const CLEAR_FINANCIAL_PROJECT_SNAPSHOT = 'CLEAR_FINANCIAL_PROJECT_SNAPSHOT';
export const CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION = 'CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_PAGINATION';
export const CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_SORT = 'CLEAR_FINANCIAL_PROJECT_SNAPSHOTS_SORT';
export const CLEAR_MANUAL_FINANCIAL_SNAPSHOTS = 'CLEAR_MANUAL_FINANCIAL_SNAPSHOTS';
export const CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION = 'CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_PAGINATION';
export const CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_FILTER = 'CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_FILTER';
export const CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_SORT = 'CLEAR_MANUAL_FINANCIAL_SNAPSHOTS_SORT';

/************* SALE_ORDERS **************/

export const GET_FINANCIAL_PROJECT_SALE_ORDERS = 'GET_FINANCIAL_PROJECT_SALE_ORDERS';
export const SEARCH_FINANCIAL_PROJECT_SALE_ORDERS = 'SEARCH_FINANCIAL_PROJECT_SALE_ORDERS';
export const EXPORT_FINANCIAL_PROJECT_SALE_ORDERS = 'EXPORT_FINANCIAL_PROJECT_SALE_ORDERS';
export const GET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER = 'GET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER';
export const POST_FINANCIAL_PROJECT_SALE_ORDER = 'POST_FINANCIAL_PROJECT_SALE_ORDER';
export const DELETE_FINANCIAL_PROJECT_SALE_ORDER = 'DELETE_FINANCIAL_PROJECT_SALE_ORDER';
export const DELETE_MULTIPLE_FINANCIAL_PROJECT_SALE_ORDERS = 'DELETE_MULTIPLE_FINANCIAL_PROJECT_SALE_ORDERS';
export const PUT_FINANCIAL_PROJECT_SALE_ORDER = 'PUT_FINANCIAL_PROJECT_SALE_ORDER';

export const SET_FINANCIAL_PROJECT_SALE_ORDERS = 'SET_FINANCIAL_PROJECT_SALE_ORDERS';
export const SET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER = 'SET_SELECTED_FINANCIAL_PROJECT_SALE_ORDER';
export const SET_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION = 'SET_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION';
export const SET_FINANCIAL_PROJECT_SALE_ORDERS_SORT = 'SET_FINANCIAL_PROJECT_SALE_ORDERS_SORT';
export const SET_FINANCIAL_PROJECT_SALE_ORDERS_FILTER = 'SET_FINANCIAL_PROJECT_SALE_ORDERS_FILTER';

export const SET_ALL_FINANCIAL_RT_COST_PROFILE = 'SET_ALL_FINANCIAL_RT_COST_PROFILE';
export const SET_SELECTED_FINANCIAL_RT_COST_PROFILE = 'SET_SELECTED_FINANCIAL_RT_COST_PROFILE';

export const CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE = 'CLEAR_SELECTED_FINANCIAL_RT_COST_PROFILE'
export const GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_CODE = 'GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_CODE';
export const GET_ALL_FINANCIAL_RT_COST_PROFILE = 'GET_ALL_FINANCIAL_RT_COST_PROFILE';
export const GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_ENTITY = 'GET_ALL_FINANCIAL_RT_COST_PROFILE_BY_ENTITY';

export const SET_ALL_FINANCIAL_COST_PROFILE_SORT = 'SET_ALL_FINANCIAL_COST_PROFILE_SORT'
export const POST_FINANCIAL_RT_COST_PROFILE = 'POST_FINANCIAL_RT_COST_PROFILE';

export const PUT_FINANCIAL_RT_COST_PROFILE = 'PUT_FINANCIAL_RT_COST_PROFILE';

export const DELETE_FINANCIAL_RT_COST_PROFILE = 'DELETE_FINANCIAL_RT_COST_PROFILE';

export const IMPORT_RT_COST_PROFILE = 'IMPORT_RT_COST_PROFILE';
export const EXPORT_RT_COST_PROFILE = 'EXPORT_RT_COST_PROFILE';
export const CLEAR_FINANCIAL_PROJECT_SALE_ORDERS = 'CLEAR_FINANCIAL_PROJECT_SALE_ORDERS';
export const CLEAR_SELECTED_FINANCIAL_PROJECT_SALE_ORDER = 'CLEAR_SELECTED_FINANCIAL_PROJECT_SALE_ORDER';
export const CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION = 'CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_PAGINATION';
export const CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_SORT = 'CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_SORT';
export const CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_FILTER = 'CLEAR_FINANCIAL_PROJECT_SALE_ORDERS_FILTER';


/************* ECHANCIER **************/

export const GET_FINANCIAL_SALE_ORDER_ECHANCIERS = 'GET_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const SEARCH_FINANCIAL_SALE_ORDER_ECHANCIERS = 'SEARCH_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const EXPORT_FINANCIAL_SALE_ORDER_ECHANCIERS = 'EXPORT_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const GET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER = 'GET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER';
export const POST_FINANCIAL_SALE_ORDER_ECHANCIER = 'POST_FINANCIAL_SALE_ORDER_ECHANCIER';
export const DELETE_FINANCIAL_SALE_ORDER_ECHANCIER = 'DELETE_FINANCIAL_PROJECT_SALE_ORDER_ECHANCIER';
export const DELETE_MULTIPLE_FINANCIAL_SALE_ORDER_ECHANCIERS = 'DELETE_MULTIPLE_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const PUT_FINANCIAL_SALE_ORDER_ECHANCIER = 'PUT_FINANCIAL_SALE_ORDER_ECHANCIER';

export const SET_FINANCIAL_SALE_ORDER_ECHANCIERS = 'SET_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const SET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER = 'SET_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER';
export const SET_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION = 'SET_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION';
export const SET_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT = 'SET_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT';
export const SET_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER = 'SET_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER';

export const CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS = 'CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS';
export const CLEAR_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER = 'CLEAR_SELECTED_FINANCIAL_SALE_ORDER_ECHANCIER';
export const CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION = 'CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_PAGINATION';
export const CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT = 'CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_SORT';
export const CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER = 'CLEAR_FINANCIAL_SALE_ORDER_ECHANCIERS_FILTER';


/************* SALE_TYPE **************/

export const GET_SALE_TYPE = 'GET_SALE_TYPE';
export const GET_ALL_SALE_TYPES = 'GET_ALL_SALE_TYPES';
export const POST_SALE_TYPE = 'POST_SALE_TYPE';
export const PUT_SALE_TYPE = 'PUT_SALE_TYPE';
export const DELETE_SALE_TYPE = 'DELETE_SALE_TYPE';
export const SET_ALL_SALE_TYPES = 'SET_ALL_SALE_TYPES';
export const EXPORT_SALE_TYPES = 'EXPORT_SALE_TYPES';
export const IMPORT_SALE_TYPES='IMPORT_SALE_TYPES';

/************* Parameters **************/


/************* COLLABORATOR COST HISTORY **************/
export const GET_COLLABORATOR_TJM_HISTORY  = 'GET_COLLABORATOR_TJM_HISTORY';
export const SET_COLLABORATOR_TJM_HISTORY = 'SET_COLLABORATOR_TJM_HISTORY';
export const CLEAR_COLLABORATOR_TJM_HISTORY = 'CLEAR_COLLABORATOR_TJM_HISTORY'
export const PUT_COLLABORATOR_TJM_HISTORY = 'PUT_COLLABORATOR_TJM_HISTORY'
/************* COLLABORATOR COST HISTORY **************/
export const GET_FINANCIAL_COLLABORATOR_COST_HISTORY  = 'GET_FINANCIAL_COLLABORATOR_COST_HISTORY';

export const POST_FINANCIAL_COLLABORATOR_COST_HISTORY='POST_FINANCIAL_COLLABORATOR_COST_HISTORY';

export const DELETE_FINANCIAL_COLLABORATOR_COST_HISTORY='DELETE_FINANCIAL_COLLABORATOR_COST_HISTORY';

export const PUT_FINANCIAL_COLLABORATOR_COST_HISTORY='PUT_FINANCIAL_COLLABORATOR_COST_HISTORY';

export const SET_FINANCIAL_COLLABORATOR_COST_HISTORY = 'SET_FINANCIAL_COLLABORATOR_COST_HISTORY';
export const SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID = 'SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID';
export const SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION = 'SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION';
export const SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT = 'SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT';
export const SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER = 'SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER';


export const CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY = 'CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY';
export const CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION = 'CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION';
export const CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT = 'CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT';
export const CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER = 'CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER';



/************* COLLABORATOR MONTHLY COST **************/
export const GET_FINANCIAL_COLLABORATOR_MONTHLY_COST  = 'GET_FINANCIAL_COLLABORATOR_MONTHLY_COST';

export const POST_FINANCIAL_COLLABORATOR_MONTHLY_COST='POST_FINANCIAL_COLLABORATOR_MONTHLY_COST';

export const DELETE_FINANCIAL_COLLABORATOR_MONTHLY_COST='DELETE_FINANCIAL_COLLABORATOR_MONTHLY_COST';

export const PUT_FINANCIAL_COLLABORATOR_MONTHLY_COST='PUT_FINANCIAL_COLLABORATOR_MONTHLY_COST';

export const SET_FINANCIAL_COLLABORATOR_MONTHLY_COST = 'SET_FINANCIAL_COLLABORATOR_MONTHLY_COST';
export const SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION = 'SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION';
export const SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT = 'SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT';
export const SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER = 'SET_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER';


export const CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST = 'CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST';
export const CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION = 'CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_PAGINATION';
export const CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT = 'CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_SORT';
export const CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER = 'CLEAR_FINANCIAL_COLLABORATOR_MONTHLY_COST_FILTER';

export const EXPORT_COLLABORATOR_MONTHLY_COST = 'EXPORT_COLLABORATOR_MONTHLY_COST'
export const IMPORT_COLLABORATOR_MONTHLY_COST = 'IMPORT_COLLABORATOR_MONTHLY_COST'