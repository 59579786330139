import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Box, Checkbox, Popper, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';


function ProjectSelect({ projects, value, handleValueChange, variant="standard", size="medium", inputStyle, checkmark = false, disabled = false, inputLabel, multiselect = false,error }) {

    const { t } = useTranslation();
    const classes = useStyles();
    const projectsList = useSelector(({ operational }) => operational.actions.projects);

    const sortOptions = (options) => {
        return options && options.sort((a, b) => a.status?.id - b.status?.id || a.name.localeCompare(b.name));
    };

    const uniSelect = () => (
        <Autocomplete
            value={value}
            options={projects ? sortOptions(projects) : sortOptions(projectsList)}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(project) => project.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(project) => project.name}
            groupBy={(project) => project.status?.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error={error}
                    variant={variant}
                    label={inputLabel || t('project')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_project_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper }}
            disabled={disabled}
        />
    );
    
    const multiSelect = () => (
        <Autocomplete
            multiple={true}
            disabled={disabled}
            value={value}
            options={projects ? sortOptions(projects) : sortOptions(projectsList)}
            onChange={(e, val) => handleValueChange(val)}
            getOptionLabel={(project) => project.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(project) => (
                <Box style={{flexShrink: 0}} >
                    {
                        checkmark &&
                        <Checkbox 
                            size="small" 
                            checked={value?.map(v => v.id).includes(project.id)} 
                        />
                    }
                    {project.name}
                </Box>
            )}
            groupBy={(project) => project.status?.name}
            renderTags={(value) => (
                <Typography 
                    noWrap={true}
                >
                    {value.map(o => o.name).join(', ')}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error={error}

                    variant={variant}
                    label={inputLabel || t('client_col')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_project_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper, option: classes.option }}
            className={classes.root}
            PopperComponent={props => (
                <Popper 
                    {...props} 
                    style={{minWidth: props.style.width}} 
                    placement="bottom-start"
                />
            )}
        />
    );

    return multiselect
        ? multiSelect()
        : uniSelect();

}

export default ProjectSelect;