import React from 'react';
import {BREADCRUMBS} from '../../../utils/format';

const FinancialProjectsList = React.lazy(() => import('../pages/FinancialProjectsList'));
const ProjectDashboard = React.lazy(() => import('../pages/ProjectDashboard'));
const CollaboratorMonthlyCost = React.lazy(() => import('../pages/CollaboratorMonthlyCost'));
const ManualFnSnapshots = React.lazy(() => import('../pages/ManualFnSnapshots'));
const CollaboratorConsumption = React.lazy(() => import('../pages/CollaboratorConsumption'));

export default [
    {
        path: '/financial/projects',
        exact: true,
        name: 'projects_list',
        component: FinancialProjectsList,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-financial',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/financial/projects/favorites',
        exact: true,
        name: 'favorites',
        component: FinancialProjectsList,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-financial',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/financial/projects(/favorites)?/:id([0-9]+)',
        exact: true,
        name: 'project',
        component: ProjectDashboard,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-financial',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/financial/collaborators/costs',
        exact: true,
        name: 'costs',
        component: CollaboratorMonthlyCost,
        permission: 'pilotage.managePilotage',
        roles: [],
        private: true,
        className: 'breadcrumb-financial',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: "/financial/snapshots",
        exact: true,
        name: "snapshots",
        component: ManualFnSnapshots,
        permission: "pilotage.managePilotage",
        roles: [],
        private: true,
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: "/financial/collaborators/consumption",
        exact: true,
        name: "consumption",
        component: CollaboratorConsumption,
        permission: "pilotage.managePilotage",
        roles: [],
        private: true,
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
];
